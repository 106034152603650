.companies {
    padding: 80px 20px;
    background-color: #f8f9fa;

    h2 {
        font-size: 2.5em;
        margin-bottom: 20px;
        text-align: center;
        color: var(--primary-color);
    }

    p {
        text-align: center;
        max-width: 600px;
        margin: 0 auto 40px;
        color: #555;
        font-size: 1.1em;
    }

    .companies-grid {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 40px;
        max-width: 1200px;
        margin: 0 auto;
    }

    .company-logo {
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;

        &:hover {
            transform: translateY(-5px);
        }

        img {
            max-width: 80%;
            max-height: 80%;
            object-fit: contain;
        }
    }

    @media (max-width: 768px) {
        .company-logo {
            width: 120px;
            height: 120px;
        }
    }
}