.about {
    padding: 80px 0;
    background-color: var(--light-blue-color);
    color: var(--black-color);

    .about-content {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
    }

    h2 {
        font-size: 2.5em;
        margin-bottom: 30px;
        text-align: center;
        color: var(--black-color);
    }

    .about-grid {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 40px;
        align-items: start;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    .about-text {
        p {
            font-size: 1.1em;
            line-height: 1.6;
            margin-bottom: 20px;
        }

        ul {
            margin-bottom: 20px;
            padding-left: 20px;

            li {
                font-size: 1.1em;
                line-height: 1.6;
                margin-bottom: 10px;
            }
        }
    }

    .about-stats {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 20px;
        
        .stat-item {
            background-color: var(--light-gray-color);;
            border-radius: 8px;
            padding: 20px;
            text-align: center;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;

            &:hover {
                transform: translateY(-5px);
            }

            .stat-number {
                display: block;
                font-size: 2.5em;
                font-weight: bold;
                color: #007bff;
                margin-bottom: 5px;
            }

            .stat-label {
                font-size: 1em;
                color: #666;
            }
        }
    }

    @media (max-width: 768px) {
        .about-stats {
            margin-top: 30px;
        }
    }
}