.app__home {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, var(--light-gray-blue-color), var(--white-color));
    padding: 2rem;
  
    &-content {
      text-align: center;
      max-width: 800px;
    }
  
    h1 {
      font-size: 3rem;
      color: var(--dark-gray-blue-color);
      margin-bottom: 1rem;
    }
  
    h2 {
      font-size: 1.5rem;
      color: var(--gray-blue-color);
      margin-bottom: 1.5rem;
    }
  
    p {
      font-size: 1.1rem;
      color: var(--gray-blue-color);
      margin-bottom: 2rem;
      line-height: 1.6;
    }
  
    &-cta {
      padding: 0.8rem 1.5rem;
      font-size: 1rem;
      background-color: var(--secondary-color);
      color: var(--white-color);
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease;
  
      &:hover {
        background-color: var(--secondary-color-dark, #0056b3); // Fallback color if variable not set
        transform: translateY(-2px);
      }
  
      &:active {
        transform: translateY(1px);
      }
    }
  
    @media screen and (max-width: 768px) {
      h1 {
        font-size: 2.5rem;
      }
  
      h2 {
        font-size: 1.3rem;
      }
  
      p {
        font-size: 1rem;
      }
    }
  }