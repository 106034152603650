.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    // background: rgb(255, 255, 255, 0.33);
    background: rgb(71, 111, 134, 0.33);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid (71, 111, 134, 0.18);
    position: fixed;
    z-index: 2;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 100px;

    img {
        width: 100%;
        height: 100%;
    }

    @media screen and (min-width:2000px) {
        max-width: 200px;
    }
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;

    li {
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;


        div {
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;
            margin-bottom: 5px;

        }

        a {
            color: var(--gray-blue-color);
            text-decoration: none;
            text-transform: uppercase;
            flex-direction: column;
            font-weight: 500;
            font-size: 16px;
            transition: all 0.5s ease-in-out;

            &:hover {
                color: var(--secondary-color);
            }


        }

        &:hover {
            div {
                background: var(--secondary-color);
            }
        }
    }

    @media screen and (max-width:800px) {
        display: none;
    }
}

.app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);

    svg {
        width: 60%;
        height: 60%;
        color: var(--white-color);
    }

    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding: 1rem;
        width: 75%;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        background: url('../../assets/bgWhite.png');
        background-color: var(--white-color);
        background-size: cover;
        background-repeat: repeat;
        box-shadow: 0 0 2px rgba(168, 168, 168, 0.15);

        svg {
            width: 35px;
            height: 35px;
            color: var(--secondary-color);
            margin: 0.5rem 1rem;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li {
                display: flex;
                width: 100%;
                transition: all 0.2s ease-out;
                margin: 10px;
                padding: 10px;

                &:hover {
                    background-color: var(--light05-gray-blue-color);
                }

                a {
                    width: 100%;
                    color: var(--dark-gray-blue-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }
        }

        @media screen and (min-width:800px) {
            display: none;

        }
    }

    @media screen and (min-width:800px) {
        display: none;

    }
}