.skills {
    padding: 80px 0;
    background: #ffffff;
  
    .skills-content {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
    }
  
    h2 {
      font-size: 2.5em;
      margin-bottom: 20px;
      text-align: center;
      color: #333;
    }
  
    .skills-description {
      text-align: center;
      max-width: 600px;
      margin: 0 auto 40px;
      color: #666;
      font-size: 1.1em;
    }
  
    .skills-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 30px;
    }
  
    .skill-item {
      background: #f9f9f9;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
  
      &:hover {
        transform: translateY(-5px);
      }
    }
  
    .skill-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
  
      h3 {
        margin: 0;
        font-size: 1.2em;
        color: #333;
      }
  
      .skill-percentage {
        font-weight: bold;
        color: #007bff;
      }
    }
  
    .skill-bar {
      height: 10px;
      background: #e0e0e0;
      border-radius: 5px;
      overflow: hidden;
  
      .skill-level {
        height: 100%;
        background: linear-gradient(90deg, #007bff, #00c6ff);
        transition: width 1s ease-in-out;
      }
    }
  
    @media (max-width: 768px) {
      padding: 60px 0;
  
      .skills-grid {
        grid-template-columns: 1fr;
      }
    }
  }