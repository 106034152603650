.portfolio {
    padding: 80px 20px;
    max-width: 1200px;
    margin: 0 auto;

    h2 {
        font-size: 2.5em;
        margin-bottom: 40px;
        text-align: center;
    }

    .projects-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 30px;
    }

    .project-card {
        background-color: #ffffff;
        border-radius: 10px;
        padding: 25px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }

        h3 {
            font-size: 1.6em;
            margin-bottom: 15px;
            color: var(--primary-color);
        }

        p {
            margin-bottom: 20px;
            color: #555;
            line-height: 1.6;
        }

        .technologies {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;

            .tech-tag {
                background-color: var(--secondary-color);
                color: white;
                padding: 5px 10px;
                border-radius: 20px;
                font-size: 0.9em;
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }

        .project-links {
            display: flex;
            justify-content: space-between;

            a {
                display: flex;
                align-items: center;
                color: var(--primary-color);
                text-decoration: none;
                font-weight: bold;
                transition: color 0.3s ease;

                &:hover {
                    color: var(--secondary-color);
                }

                svg {
                    margin-right: 5px;
                }
            }
        }
    }
}